import React, { useState, useEffect } from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import { Link as GatsbyLink } from "gatsby"
// import Img, { GatsbyImageSharpFluid } from "gatsby-image"
import Layout from "../../components/Layouts/Layout"
import {
    HeroAngleRightImage as Hero,
    LogoCloudSimple as LogoCloud,
    LogoCloudSimpleBranded as LogoCloudBranded,
    FeatureAlternatingWithTestimonial as Feature,
    TestimonialImageOverlapping as Testimonial,
    BlogSectionThreeColumns as VideoSection,
    BlogSectionThreeColumnsBadge as BlogSection,
    StatsCard as Stats,
    ContactSimpleTwoColumns as Contact,
    modelFactory
} from "@domenicosolazzo/site-designsystem"

// const videos = require("../../../content/videos/youtube/youtube.json")

  
export default function SocialMediaPage(props) {

//     const staticQuery = useStaticQuery(graphql`
//         query {
//             file(relativePath: { eq: "domenicosolazzo.jpg" }) {
//                 childImageSharp {
//                     # Specify a fluid image and fragment
//                     # The default maxWidth is 800 pixels
//                     fluid(quality: 100, maxWidth: 1000) {
//                         ...GatsbyImageSharpFluid
//                     }
//                 }
//             }
//             allMdx(sort: {order: DESC, fields: frontmatter___date}, limit: 3) {
//               edges {
//                 node {
//                   excerpt
//                   fileAbsolutePath
//                   frontmatter {
//                     topic
//                     topImageAlt
//                     topImage
//                     title
//                     tags
//                     path
//                     datePublished
//                     authorName
//                     date
//                   }
//                   id
//                   slug
//                   tableOfContents
//                   timeToRead
//                   wordCount {
//                     words
//                     sentences
//                     paragraphs
//                   }
//                 }
//               }
//               max(field: timeToRead)
//               min(field: timeToRead)
//               totalCount
//               pageInfo {
//                 currentPage
//                 hasNextPage
//                 hasPreviousPage
//                 itemCount
//                 pageCount
//                 perPage
//                 totalCount
//               }
//             }
            
//         }
//     `)

//     const img = (
//         <Img
//             fluid={staticQuery.file.childImageSharp.fluid}
//             alt="Domenico Solazzo | Developer & Engineering manager"
//             className={""}
//           />
//     )
//     const hero = modelFactory.getModel({
//         type: "hero",
//         options: {
//             heading: {
//                 text: "Hi, everyone👋"
//             },
//             subheading: {
//                 text: "I am a tech lead and programmer, passionate about creating digital products, staying healthy and make a positive impact in people's lives."
//             },
//             image: {
//                 component: img,
//                 alt: 'Domenico Solazzo | Developer & Engineering manager',
//             }
//         }
//     })


//     const {
//         edges=[],
//     } = staticQuery.allMdx

//     const blogData = edges.map(edge => {
//         const item = edge.node;
//         const blogListItemLink = modelFactory.getModel({
//             type: "link",
//             options: {
//               url: `/${item.slug}`,
//               component: GatsbyLink,
//               hrefAttribute: "to",
//               linkText: item.frontmatter?.title
//             }
//         })
//         return modelFactory.getModel({
//             type: "contentrow_item",
//             options: {
//                 heading: {
//                     text: item.frontmatter?.title,
//                     icon: modelFactory.getModel({type: "icon", options: {name: "blog"}})
//                 },
//                 content: {
//                     text: `${item.excerpt}`
//                 },
//                 author: {
//                     name: {
//                         text: "Domenico Solazzo"
//                     },
//                     image: img
//                 },
//                 date: {
//                     text: `${item.frontmatter?.datePublished}`
//                 },
//                 link: blogListItemLink,
//                 image: modelFactory.getModel({
//                     type: "image",
//                     options: {
//                         src: `${item.frontmatter?.topImage}`,
//                         alt: `${item.frontmatter?.topImageAlt}`
//                     }
//                 })
//             }
//         })
//     })  

//     const videoData=videos.items.map((item) => {
//         return modelFactory.getModel({
//             type: "contentrow_item",
//             options: {
//                 type: "video",
//                 heading: {
//                     text: item.title,
//                     icon: modelFactory.getModel({type: "icon", options: {name: "video"}})
//                 },
//                 content: {
//                     text: `${item.content}`
//                 },
//                 author: {
//                     name: {
//                         text: "Domenico Solazzo"
//                     },
//                     image: img
//                 },
//                 date: {
//                     text: `${item.date}`
//                 },
//                 link: {
//                     component: null,
//                     url: `${item.link.url}`,
//                     hrefAttribute: "href",
//                     linkText: item.title
//                 },
//                 image: modelFactory.getModel({
//                     type: "image",
//                     options: {
//                         src: `${item.image.src}`,
//                         alt: `${item.image.alt}`,
//                         className: `object-cover w-full h-56 mb-5 bg-center rounded`,
//                     }
//                 })
//             }
//         })
//     })


//     const contentRowVideoModel = modelFactory.getModel({
//         type: "contentrow",
//         options: {
//             heading: {
//                 text: "WATCH"
//             },
//             subheading: {
//                 text: "Watch the Last videos"
//             },
//             content: videoData
//         }
//     })

//     const contentRowBlogModel = modelFactory.getModel({
//         type: "contentrow",
//         options: {
//             heading: {
//                 text: "READ"
//             },
//             subheading: {
//                 text: "Read my last articles"
//             },
//             content: blogData
//         }
//     })

//     const logosRowModel = modelFactory.getModel({
//         type:"logo_row",
//         options: {
//             title: {
//                 text: "Where you can find me online"
//             },
//             data: [
//                 modelFactory.getModel({type: "image", options: {name: "linkedin", size: {width: 80, height: 80}}}),
//                 modelFactory.getModel({type: "image", options: {name: "facebook", size: {width: 80, height: 80}}}),
//                 modelFactory.getModel({type: "image", options: {name: "instagram", size: {width: 80, height: 80}}}),
//                 modelFactory.getModel({type: "image", options: {name: "youtube", size: {width: 80, height: 80}}}),
//             ],
//             metadata: {
//                 gridCols: {
//                     lg: 4,
//                     basic: 4,
//                     autoCols: false,
//                 }
//             }
//         }
//     })
    return(
        <Layout>
            <Hero />
            <LogoCloud />
            <Stats />
            <BlogSection />
            <Feature />
            <Testimonial />
            <VideoSection />
            <LogoCloudBranded />
            <Contact />
        </Layout>
    )
}